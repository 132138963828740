<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Printing/exporting options</h3>

	<p><span v-html="app_name"/> includes a number of options for exporting data for a framework.</p>
	<p><img alt="Print example image" srcset="/docimages/export_options-1.png 4x" class="k-help-img float-right"><b>Print</b> (or save as PDF)</p>
	<ul class="k-no-clear">
		<li>Click the “<v-icon small>fas fa-print</v-icon> PRINT” button from the <span v-html="link('document_card', 'document card')"></span> or the <v-icon small>fas fa-print</v-icon> icon from an <span v-html="link('item_card', 'item card')"></span> to open the interface shown below, from which you can print or save as a PDF:<img alt="Print settings example image" srcset="/docimages/export_options-2.png 4x" class="k-help-img"></li>
		<li>Controls for exactly what to print/save are shown on the left: <ul>
			<li>Print: choose to print the selected item only, or the selected item and its “descendents” in the framework hierarchy</li>
			<li>Item Fields: choose which fields to print for each item. (If you print the framework document, you can also choose which metadata fields of the document to include.)</li>
			<li>Font face and size: choose your preferred options</li>
			<li>Color-code item types: turn this option off to print in black-and-white</li>
			<li>Introductory Text: if you enter text here, it will be included at the start of the printout/PDF. Note that you can include images and links here.</li>
		</ul></li>
		<li>The right side of the interface shows a preview of how the printout/pdf will appear. If you update options, click “REFRESH PREVIEW”.</li>
		<li>Once you’re satisfied with the preview, click “PRINT”, then use your browser’s print functionality to send output to a printer or save as a PDF. You can also open the preview in a new window. (Some find it easier to read through a long list of standards when outputted in this format in a new window.)</li>
	</ul>

	<p><b>Export to spreadsheet</b></p>
	<ul><li>You can export items (e.g. standards) or associations to a CSV (spreadsheet) format from the <span v-html="link('tableitemsview', 'items table')"></span> or <span v-html="link('tableassociationsview', 'associations table')"></span></li></ul>
	
	<p><img alt="Export options example image" srcset="/docimages/export_options-3.png 4x" class="k-help-img float-right"><b>Download CASE JSON</b></p>
	<ul class="k-no-clear">
		<li>Choose “Download this framework’s CASE JSON” from the <v-icon small class="mx-1">fas fa-ellipsis-v</v-icon> <span v-html="link('kebab_menu', 'kebab menu')"></span> to export the framework data in its raw JSON format.</li>
		<li>Note that you can choose whether to export the “CASE 1.0” version of your framework’s JSON, which includes only fields that are part of the official CASE 1.0 specification, or the “Extended CASE” version, which includes additional fields that are included in <span v-html="app_name"/> but are not part of the official CASE specification (e.g. Supplemental Information fields for items).</li>
	</ul>
	
	<p><b>CASE REST APIs</b></p>
	<ul><li>A framework’s CASE JSON is also available via the <span v-html="link('case_rest_api', 'CASE REST API')"></span>; you can copy the URL for the CASE <code>CFPackage</code> API from the kebab menu.</li></ul>
	
	<p><b>Satchel text format</b></p>
	<ul><li>The <span v-html="app_name"/> system includes a powerful tool for batch-importing items in a special “Satchel text format”. When editing a framework, you can also export items in this Satchel text format; this allows you to, for example, edit the exported output then import items using the batch tool into the same or another framework.</li></ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	